// extracted by mini-css-extract-plugin
export const body = "entity-module--body--2OW7s";
export const infoBar = "entity-module--info-bar--2X8FJ";
export const description = "entity-module--description--2mKEN";
export const wrapper = "entity-module--wrapper--zUXMz";
export const image = "entity-module--image--3klv3";
export const listHeading = "entity-module--listHeading--18NDD";
export const txtSpacing = "entity-module--txtSpacing--bjAbe";
export const alignRight = "entity-module--alignRight--3BrUG";
export const fullWidth = "entity-module--fullWidth--2QEIY";
export const iconStyles = "entity-module--iconStyles--3k4Ks";